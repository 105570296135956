import '../styles/globals.css'
import GoogleAnalytics from 'components/google-analytics'
import { ErrorBoundary } from '@sentry/react'
import Layout from 'components/layout'
import ErrorPage from 'pages/wrong'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { pageview } from 'lib/gTag'
import { useEffect } from 'react'

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

export const theme = extendTheme({
  config,
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  colors: {
    textPrimary: {
      dark: 'black',
      light: 'white',
    },
    textSecondary: {
      light: 'gray.400',
      dark: 'gray.500',
    },
    background: {
      dark: '#1A202C',
      light: 'white',
    },
  },
})

function MyApp({ Component, pageProps, session }) {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
  return (
    <ErrorBoundary fallback={(props) => <ErrorPage error={props.error} />}>
      <ChakraProvider theme={theme}>
        <Layout>
          <Component {...pageProps} />
          <GoogleAnalytics GA_MEASUREMENT_ID={process.env.NEXT_PUBLIC_GA_ID} />
        </Layout>
      </ChakraProvider>
    </ErrorBoundary>
  )
}

export default MyApp
